<template>
	<div class="animated fadeIn">
		<div class="card" no-body>
			<div class="header">
				<h3>Welcome {{user && user.displayName ? user.displayName : ""}} </h3>
			</div>
			<div>
				<div>
					<div>
						<div>
							Cognito is Authorized: {{!!cognito.attributes.sub}}
						</div>
						<div>
							Cognito User: {{cognito.username}}
						</div>
            <div>
              Email: {{cognito.attributes.email}}
            </div>
            <div>
              Name: {{cognito.attributes.name}}
            </div>
						<div>
							Cognito Company: {{cognito.attributes ? cognito.attributes['custom:orgName'] : ''}}
						</div>
					</div>

					<div v-admin>
						<div>
							Database User is Administrator: {{user ? user.administratorOf : "Loading..."}}
						</div>
						<div>
							<v-btn color="primary" @click="dumpJwt">Dump JWT</v-btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {VueController} from '../service/support/vuex-controller';
	import Auth from '@aws-amplify/auth';
	import vaultService from '../service/vault-service';

	export default VueController("Reports", {
		state: [
			"cognito",
			"user",
			"organization"
		],
		computed: {
			filteredInvites() {
				if (this.user && this.user.vaults) {
					return this.user.vaults.filter(function(vault) {
						return vault.invitation_accepted.match(/none/) && vault._deleted !== 1;
					})
				}
			}
		},
		methods: {
			acceptInvite(vaultInvite) {
				//console.log(JSON.stringify(vaultInvite));
				vaultService.mutation("vaultInvite",{vaultId : vaultInvite.vault_id, acceptInvite: true }).then(() => {
					vaultService.query("getUserById",undefined,"user",{vuexCache: false});
				});
			},
			denyInvite(vaultInvite) {
				vaultService.mutation("vaultInvite",{vaultId : vaultInvite.vault_id, acceptInvite: false }).then(() =>{
					vaultService.query("getUserById",undefined,"user",{vuexCache: false});
				});
			},
			dumpJwt() {
				//Auth.currentSession().then(result => console.log(result.getIdToken().getJwtToken()));
			}
		}
	});
</script>
